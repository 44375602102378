<template>
  <div>
    <Header />
 <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="straightairscrewdrivers()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/Straight.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Straight Air Screwdrivers</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="pistolairscrewdrivers()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/Pistol.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Pistol Air Screwdrivers </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="forwardpistol()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/Forward.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Forward Pistol Grip Air Screwdrivers</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="forwardpistol3position()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/Forward_3position.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Forward Pistol Grip Air Screwdrivers With 3 Position</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="pistolupgrip()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/UpGrip.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Pistol UpGrip Air Screwdrivers </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="angelairscrew()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/Angel.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Angel Air Nutrunners </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="straightairscrewsuction()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 100%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/Suction.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Straight Air Screwdrivers With Srew Suction Device</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
         <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="airtighteningsystems()">
            <div class="card" style="width: 260px; height: 180px">
              <img
                style="max-height: 80%; max-width: 50%; margin: auto"
                class="card-img-top img-fluid"
                src="../../../assets/images/subcategorie/auxiliary_equipement/pneumatique_screw_devices/Tightening.jpg"
                alt="Card image cap"
              />
              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Air Tightening Systems</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../components/Header.vue";

import Footer from "../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    straightairscrewdrivers(){
      window.location.href = "https://www.fiamgroup.com/en/products/straight-air-screwdrivers/";
   
    },

  pistolairscrewdrivers(){
    window.location.href = "https://www.fiamgroup.com/en/products/pistol-air-screwdrivers/";
  
    },

  forwardpistol(){
    window.location.href = "https://www.fiamgroup.com/en/products/forward-pistol-grip-air-screwdrivers/";
     
    },

  forwardpistol3position(){
    window.location.href = "https://www.fiamgroup.com/en/products/forward-pistol-grip-air-screwdrivers-with-3-position-air-inlet/";
 
    },
    pistolupgrip(){
      window.location.href = "https://www.fiamgroup.com/en/products/pistol-upgrip-air-screwdrivers/";
     
    },
    angelairscrew(){
      window.location.href = "https://www.fiamgroup.com/en/products/angle-air-nutrunners/";
    
    },
    straightairscrewsuction(){
      window.location.href = "https://www.fiamgroup.com/en/products/straight-air-screwdrivers-with-screw-suction-device/";
  
    },
    airtighteningsystems(){
      window.location.href = "https://www.fiamgroup.com/en/products/tom-monitoring-unit-for-the-tightening-cycle/";
  
    },
  },
};
</script>